import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import VueApexCharts from 'vue3-apexcharts'
import VueGtag from 'vue-gtag'

import amplify_outputs from '../amplify_outputs.json'
import apiService  from './services/api.service'

const emailEndpoint = amplify_outputs?.custom?.API["alcala-la-real-api"]?.endpoint || ""
apiService.setEmailEndpoint(emailEndpoint)

const app = createApp(App)

app.use(store)
app.use(router)
app.use(VueApexCharts)
app.use(VueGtag, {
    config: { id: "G-LPV4VSC2JV" }
})

app.mount('#app')
